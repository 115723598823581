'use client';

import React from 'react';
import { useTheme } from '@mui/material';
import { mergeSx } from '@jucy-ui/appearance';
import { AspectRatio } from '@jucy-ui/components';
import { Image, ImageProps } from '@/components/Image';

export type CardImageProps = ImageProps;

export const CardImage = ({ sx, fill = true, sizes, ...props }: CardImageProps) => {
    const theme = useTheme();
    return (
        <AspectRatio>
            <Image
                {...props}
                alt={props.alt || ''}
                fill={fill}
                sizes={
                    typeof sizes === 'undefined'
                        ? `(max-width: ${theme.breakpoints.values.xl}${theme.breakpoints.unit}) 370px,
                            (max-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) 363px,
                            (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) 300px,
                            (max-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) 224px,
                            370px`
                        : sizes
                }
                sx={mergeSx(
                    (theme) => ({
                        height: 'auto',
                        maxWidth: '100%',
                        borderRadius: 1,
                        transition: theme.transitions.create(['opacity', 'box-shadow']),
                        opacity: 1,
                    }),
                    sx
                )}
            />
        </AspectRatio>
    );
};
