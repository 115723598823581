'use client';

import {Theme} from '@mui/material';
import {SxProps} from '@mui/system';
import React from 'react';
import Card from '@mui/material/Card';
import { mergeSx } from '@jucy-ui/appearance';
import WebsiteSearchForm, { WebsiteSearchFromProps } from './WebsiteSearchForm';
import { useSearchFormContainerQuery } from './hooks/useSearchFormContainerQuery';

export interface SearchFormCardLargeProps extends WebsiteSearchFromProps {
    sx?: SxProps<Theme>;
}

export const SearchFormCardLarge = ({ sx, children, ...props }:SearchFormCardLargeProps) => {
    const smallContainerQuery = useSearchFormContainerQuery();
    return (
        <Card
            variant="outlined"
            sx={mergeSx(
                (theme) => ({
                    maxWidth: 704,
                    padding: theme.spacing(3, 4, 1, 4),
                    [smallContainerQuery]: {
                        borderRadius: 0,
                        padding: theme.spacing(3, 2, 3, 2),
                        mx: `-${theme.spacing(3)}`,
                    },
                }),
                sx
            )}
        >
            {children}
            <WebsiteSearchForm {...props} />
        </Card>
    );
};
