import React from 'react';
import { TypographyProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@jucy-ui/appearance';

export type CardTitleProps = TypographyProps;

export const CardTitle = React.forwardRef<HTMLDivElement, CardTitleProps>(({ children, gutterBottom = true, variant = 'subtitle2', component = 'div', sx, ...props }, ref) => (
    <Typography
        {...props}
        gutterBottom={gutterBottom}
        variant={variant}
        component={component}
        sx={mergeSx(
            {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
            },
            sx
        )}
        ref={ref}
    >
        {children}
    </Typography>
));
CardTitle.displayName = 'CardTitle';
