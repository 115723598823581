import * as React from 'react';
import { CmsBlock } from '@/components/Blocks/CmsBlock';
import { ElementalAreaContainer } from '@/components/Blocks/ElementalAreaContainer';
import { CmsBlock as CmsBlockData } from '@/services/cms/blocks';
import { CmsBaseBlock } from '@/services/cms/blocks/BaseCmsBlock';
import { CmsElementalArea } from '@/services/cms/models/CmsElementalArea';

export type ElementalAreaProps = {
    children?: CmsElementalArea;
    isSubElementalArea?: boolean;
    wrap?: boolean;
    pageLayout?: string;
};
export const ElementalArea = ({ wrap, children, isSubElementalArea, pageLayout }: ElementalAreaProps) => {
    if (!children?.elements) {
        return null;
    }
    const element = (
        <>
            {(children?.elements as CmsBaseBlock[])?.map((block, i) => (
                <CmsBlock
                    position={i}
                    isSubBlock={isSubElementalArea}
                    key={`cms-block-${block.id}`}
                    data={
                        {
                            ...block,
                            showContentContainer: pageLayout === 'BookingWidgetSideBar' ? false : block.showContentContainer,
                        } as CmsBlockData
                    }
                />
            ))}
        </>
    );
    return wrap ? <ElementalAreaContainer>{element}</ElementalAreaContainer> : element;
};
