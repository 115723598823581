'use client';

import * as React from 'react';
import Container from '@mui/material/Container';
import { ElementalArea } from '@/components/Blocks/ElementalArea';
import { FocusImage } from '@/components/FocusImage';
import { SiteContainer } from '@/components/SiteContainer';
import { TypographyRegular } from '@/components/TypographyRegular';
import { getPageBanner } from '@/lib/getPageBanner';
import { hasElementalArea } from '@/lib/hasElementalArea';
import { CmsPage } from '@/services/cms/pages/CmsPage';


export interface BlogPageWrapProps {
    pageData: CmsPage;
    children: React.ReactNode;
}

export const BlogPageWrap = ({ children, ...props }: BlogPageWrapProps) => {
    const pageData = props.pageData;
    const banner = getPageBanner(pageData);
    return (
        <>
            {banner? (
                <FocusImage
                    priority={true}
                    sx={(theme) => ({
                        height: `${theme.settings.bannerHeights.medium}px`,
                    })}
                    {...banner}
                />
            ) : null}
            <Container
                sx={(theme) => ({
                    paddingBottom: 6,
                    mt: getPageBanner(pageData) ? -2 : undefined,
                    background: theme.palette.common.white,
                    borderRadius: theme.shape.borderRadius / 2,
                    pt: 2,
                })}
                maxWidth="lg"
            >
                <TypographyRegular
                    variant="h3"
                    component="h1"
                    sx={{
                        fontWeight: '700',
                    }}
                >
                    {pageData.title}
                </TypographyRegular>
                {children}
            {hasElementalArea(pageData) ? (
                <SiteContainer sx={{pt: 4}}>
                    <ElementalArea wrap={false} pageLayout={pageData.layout}>
                        {pageData.elementalArea}
                    </ElementalArea>
                </SiteContainer>
            ) : null}
            </Container>
        </>
    );
};
