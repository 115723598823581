'use client';

import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import type { CatalogItem } from '@jucy-ui/common';
import { JucyMuiTab, JucyMuiTabs } from '@jucy-ui/components';
import {
    DetailsTab,
    ExcessTab,
    FeaturesTab,
    SpecsTab,
    fleetCategoryDetailsDialogTabNames,
    getFleetCategoryDetailsDialogTabPanelProps,
} from '@jucy-ui/components/FleetCategory/components/TeaserCard/components/FleetCategoryDetailsDialogTabs';
import { CmsFleetCategory } from '@/services/cms/models/CmsFleetCategory';

export const FleetCategoryPageTabs = ({ fleetCategory }: { fleetCategory?: CmsFleetCategory }) => {
    const params = useSearchParams();
    const routeTab = params?.get('tab');
    const [activeTab, setActiveTab] = useState(routeTab || 'details');
    const router = useRouter();
    const path = usePathname();
    const toggle = useCallback(
        (_event: React.SyntheticEvent, newValue: string) => {
            router.push(`${path}?tab=${newValue}`,{scroll:false});
            setActiveTab(newValue);
        },
        [path, router]
    );
    const hasRun = useRef<boolean>(false);
    useEffect(() => {
        if (!hasRun.current && routeTab) {
            hasRun.current = true;
            document.getElementById(`${routeTab}-tab`)?.scrollIntoView({ behavior: 'instant' });
        }
    }, [routeTab]);
    if (!fleetCategory) {
        return null;
    }
    return (
        <>
            <JucyMuiTabs value={activeTab} onChange={toggle}>
                {fleetCategoryDetailsDialogTabNames.map((value) => (
                    <JucyMuiTab key={value} {...getFleetCategoryDetailsDialogTabPanelProps(value)}  />
                ))}
            </JucyMuiTabs>
            <DetailsTab sx={theme=>({scrollMarginTop:theme.settings.appBar.spacer(2)})} activeTab={activeTab} catalogItem={fleetCategory as CatalogItem} hideImageGallery={true} />
            <FeaturesTab sx={theme=>({scrollMarginTop:theme.settings.appBar.spacer(2)})} activeTab={activeTab} catalogItem={fleetCategory as CatalogItem} />
            <SpecsTab sx={theme=>({scrollMarginTop:theme.settings.appBar.spacer(2)})} activeTab={activeTab} catalogItem={fleetCategory as CatalogItem} />
            <ExcessTab sx={theme=>({scrollMarginTop:theme.settings.appBar.spacer(20)})} activeTab={activeTab} catalogItem={fleetCategory as CatalogItem} />
        </>
    );
};
