'use client';

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import WebsiteSearchForm from '@/components/SearchForm/WebsiteSearchForm';
import { SiteContainer } from '@/components/SiteContainer';
import { CmsElementalArea } from '@/services/cms/models/CmsElementalArea';
import { CmsPage } from '@/services/cms/pages/CmsPage';
import { BlogPageWrap } from './BlogPageWrap';


export interface PageWrapProps {
    pageData: CmsPage & { elementalArea?: CmsElementalArea };
    children: React.ReactNode;
}

export const PageWrap = ({ children, ...props }: PageWrapProps) => {
    const pageData = props.pageData;
    if ((pageData.layout === 'Blog' || pageData.className === 'BlogPost')) {
        return <BlogPageWrap {...props}>{children}</BlogPageWrap>;
    }
    if (pageData.layout !== 'BookingWidgetSideBar') {
        return children;
    }
    return (
        <SiteContainer>
            <Grid container spacing={2}>
                <Grid xs={12} md={8}>
                    {children}
                </Grid>
                <Grid xs={12} md={4} sx={{ mx: 'auto' }}>
                    <Card
                        sx={(theme) => ({
                            position: {
                                xs: 'static',
                                md: 'sticky',
                            },
                            top: {
                                ...theme.settings.appBar.spacer(parseInt(theme.spacing(2))),
                                xs: 0,
                            },
                            width: '100%',
                            mb: 6,
                        })}
                    >
                        <CardContent>
                            {pageData.bookingWidget?.title ? (
                                <Typography
                                    component="h2"
                                    variant="h6"
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: 18,
                                        marginBottom: 2,
                                        marginTop: 0,
                                    }}
                                >
                                    {pageData.bookingWidget.title}
                                </Typography>
                            ) : null}
                            <WebsiteSearchForm fleetType={pageData.bookingWidget?.fleetTypeSlug} country={pageData.bookingWidget?.regionCode} location={pageData.bookingWidget?.branchCode} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SiteContainer>
    );
};
