import { CmsPage } from '@/services/cms/pages/CmsPage';

export const getPageBanner = (page: CmsPage) => {
    if (page.bannerImage) {
        return page.bannerImage;
    }
    if (page.className === 'BlogPost') {
        return page.featuredImage;
    }
    return null;
};
