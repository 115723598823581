'use client';

import * as React from 'react';
import { PageHeader } from '@/components/Pages/components/PageHeader';
import { PageWrap } from '@/components/Pages/components/PageWrap';
import { SiteContainer } from '@/components/SiteContainer';
import { UserDefinedFormHtmlContent } from '@/components/UserDefinedForm/UserDefinedFormHtmlContent';
import { PageProps } from '@/lib/PageProps';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';
import { CmsUserDefinedFormPageData } from '@/services/cms/pages/CmsUserDefinedFormPageData';

export interface BlockPageProps extends PageProps<{ slug: string[] }> {
    pageData: CmsUserDefinedFormPageData;
    siteConfig: CmsSiteConfig;
    locale?: string;
}

export const UserDefinedFormPage = (props: BlockPageProps) => (
    <>
        <PageHeader {...props} />
        <PageWrap pageData={props.pageData}>
            <SiteContainer sx={{ paddingBottom: 6 }}>
                <UserDefinedFormHtmlContent
                    formClassName={props.pageData.className}
                    formId={props.pageData.id}
                    html={props.pageData.content}
                    fields={props.pageData.fields}
                    onCompleteMessage={props.pageData.onCompleteMessage}
                    showClearButton={props.pageData.showClearButton}
                    enableLiveValidation={props.pageData.enableLiveValidation}
                    submitButtonText={props.pageData.submitButtonText}
                    clearButtonText={props.pageData.clearButtonText}
                />
            </SiteContainer>
        </PageWrap>
    </>
);
