import { CmsEditableField } from '@/services/cms/userDefinedForm/CmsEditableField';

export const extractDefaultValues = (fields: CmsEditableField[]) => {
    const values: Record<string, unknown> = {};

    for (const field of fields) {
        if (field.className === 'EditableRadioField' || field.className === 'EditableDropdown') {
            const defaultOption = field.options?.find((option) => option.default);
            values[field.name] = defaultOption?.value || undefined;
            continue;
        }
        if (field.className === 'EditableCheckboxGroupField') {
            const defaultOptions = field.options?.filter((option) => option.default).map((option) => option.value);
            if (defaultOptions?.length) {
                values[field.name] = defaultOptions;
            }
            continue;
        }
        values[field.name] = field.default;
    }
    return values;
};
