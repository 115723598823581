import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import React, { useMemo } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { MapMarker } from '@jucy-ui/components';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsMapMarker } from '@/services/cms/models/CmsMapMarker';

export interface JucyMapBranchMarkerProps {
    branch: CmsMapMarker;
    activeMarker?: google.maps.marker.AdvancedMarkerElement;
    setActiveMarker: (marker?: google.maps.marker.AdvancedMarkerElement) => void;
}

export const JucyMapBranchMarker = ({ branch, activeMarker, setActiveMarker }: JucyMapBranchMarkerProps) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const position = useMemo(() => ({ lat: branch.latitude, lng: branch.longitude }), [branch.latitude, branch.longitude]);
    const addSiteLinkPrefix = useSiteLinkPrefix();

    return (
        <>
            <AdvancedMarker ref={markerRef} position={position} onClick={() => setActiveMarker(marker || undefined)}>
                <MapMarker color="primary" />
            </AdvancedMarker>
            {activeMarker === marker ? (
                <InfoWindow anchor={marker} onCloseClick={() => setActiveMarker(undefined)}>
                    <Box sx={{ minWidth: 180 }}>
                        <Typography sx={{ fontWeight: 'bold', mb: 0.5 }}>{branch.title}</Typography>
                        {branch.address ? (
                            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', mb: 0.5 }}>
                                {branch.address}
                            </Typography>
                        ) : null}
                        {branch.link ? (
                            <Link href={addSiteLinkPrefix(branch.link)} sx={{ mb: 1, display: 'block' }}>
                                Location details <ChevronRightIcon sx={{ verticalAlign: 'middle' }} fontSize="inherit" />
                            </Link>
                        ) : null}
                        <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`https://www.google.co.nz/maps/dir/My+Location/${branch.latitude},${branch.longitude}`}
                            sx={{ display: 'block' }}
                        >
                            Get Directions <OpenInNewIcon sx={{ verticalAlign: 'middle' }} fontSize="inherit" />
                        </Link>
                    </Box>
                </InfoWindow>
            ) : null}
        </>
    );
};
