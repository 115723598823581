'use client';

import * as React from 'react';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { PageHeader } from '@/components/Pages/components/PageHeader';
import { PageWrap } from '@/components/Pages/components/PageWrap';
import { PageProps } from '@/lib/PageProps';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';
import { CmsBlogPostPageData } from '@/services/cms/pages/CmsBlogPostPageData';


export interface BlogPostPageProps extends PageProps<{ slug: string[] }> {
    pageData: CmsBlogPostPageData;
    siteConfig: CmsSiteConfig;
    locale?: string;
}

export const BlogPostPage = (props: BlogPostPageProps) => (
    <>
        <PageHeader
            {...props}
            pageData={{
                ...props.pageData,
                showTitle: false,
            }}
        />

        <PageWrap pageData={props.pageData}>
            <CmsHtmlContent html={props.pageData.content} />
        </PageWrap>
    </>
);
