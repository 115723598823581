'use client';

import dynamic from 'next/dynamic';
import { Clarity } from '@/components/Analytics/Clarity';
import { env } from '@/env';
import { CmsPage } from '@/services/cms/pages/CmsPage';

const CommissionJunction = dynamic(() => import('./CommissionJunction').then((mod) => mod.CommissionJunction));
const IntercomChatbot = dynamic(() => import('./IntercomChatbot').then((mod) => mod.IntercomChatbot));
const TradeDoubler = dynamic(() => import('./TradeDoubler').then((mod) => mod.TradeDoubler));

export interface AnalyticsProps {
    pageData?: CmsPage;
    pageSlug?: string[];
}

export const Analytics = (props: AnalyticsProps) => <>
        {env.NEXT_PUBLIC_CJ_ENTERPRISE_ID ? <CommissionJunction enterpriseId={env.NEXT_PUBLIC_CJ_ENTERPRISE_ID} {...props} /> : undefined}
        {env.NEXT_PUBLIC_INTERCOM_API_BASE_URL && env.NEXT_PUBLIC_INTERCOM_APP_ID ? (
            <IntercomChatbot
                config={{
                    app_id: env.NEXT_PUBLIC_INTERCOM_APP_ID,
                    api_base: env.NEXT_PUBLIC_INTERCOM_API_BASE_URL,
                }}
            />
        ) : undefined}
        {env.NEXT_PUBLIC_TRADEDOUBLER_ORG_ID ? <TradeDoubler {...props} /> : undefined}
        {env.NEXT_PUBLIC_CLARITY_KEY ? <Clarity clarityKey={env.NEXT_PUBLIC_CLARITY_KEY} /> : undefined}
    </>;
