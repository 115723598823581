import { EditableFormStepProps } from '@/components/UserDefinedForm/Fields/EditableFormStep';
import { CmsEditableField } from '@/services/cms/userDefinedForm/CmsEditableField';
import { CmsEditableFieldGroupData } from '@/services/cms/userDefinedForm/CmsEditableFieldGroupData';

export interface GroupedFields extends EditableFormStepProps {
    fields: CmsEditableField[];
}

export interface FieldGroup extends CmsEditableFieldGroupData {
    fields: CmsEditableField[];
}

export const groupEditableFields = (fields: CmsEditableField[]) => {
    const sortedFields: GroupedFields[] = [];

    let lastGroup: FieldGroup | undefined;
    let lastPage: GroupedFields | undefined;
    for (const field of fields) {
        if (field.className === 'EditableFormStep') {
            lastPage = {
                ...field,
                fields: [],
            };
            sortedFields.push(lastPage);
            continue;
        }
        if (field.className === 'EditableFieldGroup') {
            lastGroup = {
                ...field,
                fields: [],
            };
            if (!lastPage) {
                throw new Error('EditableFieldGroup must be inside EditableFormStep');
            }
            lastPage.fields?.push(lastGroup);
            continue;
        }
        if (field.className === 'EditableFieldGroupEnd') {
            lastGroup = undefined;
            continue;
        }
        if (lastGroup) {
            lastGroup.fields.push(field);
            continue;
        }
        if (!lastPage) {
            throw new Error('EditableField must be inside EditableFormStep');
        }
        lastPage.fields?.push(field);
    }

    return sortedFields;
};
