import * as React from 'react';
import { Element } from 'domhandler';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { UserDefinedForm, UserDefinedFormProps } from '@/components/UserDefinedForm/UserDefinedForm';

export interface UserDefinedFormHtmlContentProps extends UserDefinedFormProps {
    html?: string | null;
}

const isUserDefinedFormElement = (node: Element) => {
    const type = node.type as string;
    const data = typeof (node as unknown as { data?: unknown }).data === 'string' ? (node as unknown as { data: string }).data : '';
    return type === 'text' && data === '$UserDefinedForm';
};

export const UserDefinedFormHtmlContent = ({ html, ...props }: UserDefinedFormHtmlContentProps) => (
    <CmsHtmlContent
        html={html}
        rules={[
            {
                shouldProcessNode: (node) => {
                    if (node.childNodes?.length !== 1) {
                        return false;
                    }
                    if (node.childNodes[0].type !== 'text') {
                        return false;
                    }
                    return isUserDefinedFormElement(node.childNodes[0] as unknown as Element);
                },
                processNode: () => <UserDefinedForm key="udf-1" {...props} />,
            },
            {
                shouldProcessNode: isUserDefinedFormElement,
                processNode: () => <UserDefinedForm key="udf-2" {...props} />,
            },
        ]}
    />
);
