import * as React from 'react';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { mergeSx } from '@jucy-ui/appearance';

export type ElementalAreaContainerProps = BoxProps;
export const ElementalAreaContainer = ({ children, sx, ...props }: ElementalAreaContainerProps) => (
    <Box
        sx={mergeSx(
            {
                containerType: 'inline-size',
                paddingBottom: 6,
                '& > *:not(:last-child)': {
                    marginBottom: 9,
                },
            },
            sx
        )}
        {...props}
    >
        {children}
    </Box>
);
