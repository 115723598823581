'use client';

import { useMap } from '@vis.gl/react-google-maps';
import React, { useEffect, useState } from 'react';
import { CmsMapMarker } from '@/services/cms/models/CmsMapMarker';
import { JucyMapBranchMarker } from './JucyMapBranchMarker';

export interface JucyMapBranchMarkerListProps {
    markers: CmsMapMarker[];
}

export const JucyMapBranchMarkerList = (props: JucyMapBranchMarkerListProps) => {
    const map = useMap();
    const [activeMarker, setActiveMarker] = useState<google.maps.marker.AdvancedMarkerElement | undefined>(undefined);
    useEffect(() => {
        if (!map) {
            return;
        }
        if (props.markers.length === 1) {
            const marker = props.markers[0];
            map.setCenter({ lat: marker.latitude, lng: marker.longitude });
            map.setZoom(marker.zoom || 14);
        } else {
            const bounds = new google.maps.LatLngBounds();
            for (const marker of props.markers) {
                bounds.extend({ lat: marker.latitude, lng: marker.longitude });
            }
            map.fitBounds(bounds);
        }
    }, [map, props.markers]);
    if (!map) {
        return null;
    }
    return (
        <>
            {props.markers.map((point) => {
                const key = `${point.latitude},${point.longitude}`;
                return (
                    <React.Fragment key={key}>
                        <JucyMapBranchMarker branch={point} activeMarker={activeMarker} setActiveMarker={setActiveMarker} />
                    </React.Fragment>
                );
            })}
        </>
    );
};
