import React from 'react';
import { TypographyProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@jucy-ui/appearance';

export type CardDescriptionProps = TypographyProps;

export const CardDescription = React.forwardRef<HTMLDivElement, CardDescriptionProps>(({ children, variant = 'body2', color = 'text.secondary', sx, ...props }, ref) => (
    <Typography
        {...props}
        variant={variant}
        color={color}
        sx={mergeSx(
            {
                height: '80px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '4',
                WebkitBoxOrient: 'vertical',
            },
            sx
        )}
        ref={ref}
    >
        {children}
    </Typography>
));
CardDescription.displayName = 'CardDescription';
