'use client';

import React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { SxProps } from '@mui/system/styleFunctionSx';
import { mergeSx } from '@jucy-ui/appearance';
import { SiteContainer } from '@/components/SiteContainer';
import { CmsBaseBlock } from '@/services/cms/blocks/BaseCmsBlock';


export interface BlockContainerProps extends CmsBaseBlock {
    sx?: SxProps<Theme>;
    children: React.ReactNode;
    onClick?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>['onClick'];
}

export const BlockContainer = ({ children, showContentContainer, narrow, sx: sxIn, showBackground, showSeparatorBorder, onClick, id, className }: BlockContainerProps) => {
    const sx = mergeSx(
        {
            ...(showBackground ? { bgcolor: 'background.light' } : undefined),
            ...(showSeparatorBorder
                ? {
                      borderTopWidth: 28,
                      borderTopStyle: 'solid',
                      borderTopColor: 'primary.main',
                  }
                : undefined),
        },
        sxIn
    );
    if (narrow) {
        return (
            <Container className="JucyBlockContainer" data-element-type={className} id={`element-${id}`} maxWidth="md" sx={sx} onClick={onClick}>
                {children}
            </Container>
        );
    }

    if (showContentContainer) {
        return (
            <SiteContainer className="JucyBlockContainer" data-element-type={className} id={`element-${id}`} sx={sx} onClick={onClick}>
                {children}
            </SiteContainer>
        );
    }

    return (
        <Box className="JucyBlockContainer" data-element-type={className} id={`element-${id}`} sx={sx} onClick={onClick}>
            {children}
        </Box>
    );
};
