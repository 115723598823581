import React from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { BlockContainer } from '@/components/Blocks/BlockContainer';
import { BlockTitle } from '@/components/Blocks/components/BlockTitle';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { SearchFormCardLarge } from '@/components/SearchForm/SearchFormCardLarge';
import { CmsLocationMapBlock } from '@/services/cms/blocks/CmsLocationMapBlock';
import { JucyMapBranchMarkerList } from './components/JucyMapBranchMarkerList';
import { JucyMapContainer } from './components/JucyMapContainer';


export const LocationMapBlock = (props: CmsLocationMapBlock) => (
    <BlockContainer {...props}>
        <Grid container spacing={1}>
            <Grid xs={12} md={6}>
                <BlockTitle {...props} />
                {props.content ? <CmsHtmlContent html={props.content} /> : null}
            </Grid>
            <Grid xs={12} md={6} sx={{ display: 'flex', alignItems: 'top', justifyContent: 'center', position: 'relative' }}>
                <JucyMapContainer>
                    <JucyMapBranchMarkerList markers={props.mapMarkers || []} />
                </JucyMapContainer>
            </Grid>
        </Grid>
        {props.showBookingFrom ? (
            <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <SearchFormCardLarge sx={{width:'100%'}} fleetType={props?.fleetTypeSlug} country={props?.regionCode} location={props?.branchCode} />
            </Stack>
        ) : null}
    </BlockContainer>
);
