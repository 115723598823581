import * as React from 'react';
import ThreeSixtyRoundedIcon from '@mui/icons-material/ThreeSixtyRounded';
import { ButtonProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { Image } from '@/components/Image';
import { CmsFleetCategory } from '@/services/cms/models/CmsFleetCategory';

const ThreeSixtyIcon = () => (
    <Avatar
        sx={(theme) => ({
            bgcolor: theme.palette.primary.main,
            width: {
                xs: 50,
                sm: 100,
            },
            height: {
                xs: 50,
                sm: 100,
            },
            color: theme.palette.common.white,
            flexDirection: 'column',
            // fontSize: '25px',
            fontSize: {
                xs: 12,
                sm: 25,
            },
            textAlign: 'center',
            fontFamily: theme.typography.fontFamilyRegular,
            position: 'absolute',
            inset: 0,
            margin: 'auto',
        })}
    >
        <Box
            sx={{
                mt: {
                    xs: '5px',
                    sm: '14px',
                },
            }}
        >
            360°
        </Box>
        <ThreeSixtyRoundedIcon
            sx={{
                fontSize: {
                    xs: 30,
                    sm: 70,
                },
                mt: {
                    xs: '-8px',
                    sm: '-20px',
                },
            }}
        />
    </Avatar>
);

export const FleetCategoryCatalogImage = ({ image, sizes = '370px', onClick }: { image: Exclude<CmsFleetCategory['gallery'], undefined>[0]; sizes?: string; onClick?: ButtonProps['onClick'] }) => {
    const src = image.type === 'Image 360' ? image.thumbnail.replace('thumbnail_', '') : image.original;
    const content = (
        <>
            <Image fill={true} alt={image.originalAlt} src={src} sizes={sizes} priority={true} sx={{ objectFit: 'cover' }} />
            {image.type === 'Image 360' ? <ThreeSixtyIcon /> : null}
        </>
    );
    if (!onClick) {
        return content;
    }
    return (
        <ButtonBase
            sx={(theme) => ({
                transition: theme.transitions.create(['opacity', 'box-shadow']),
                diplay: 'block',
                width: '100%',
                height: '100%',
                '&:hover': {
                    opacity: 0.9,
                    boxShadow: theme.shadows[4],
                },
            })}
            onClick={onClick}
        >
            {content}
        </ButtonBase>
    );
};
