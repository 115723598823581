'use client';

import * as React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { CmsFleetCategory } from '@/services/cms/models/CmsFleetCategory';
import { FleetCategoryCatalogImage } from './FleetCategoryPageGalleryImage';

const FleetCategoryPageGalleryDialog = React.lazy(() => import('./FleetCategoryPageGalleryDialog').then((mod) => ({ default: mod.FleetCategoryPageGalleryDialog })));

export const FleetCategoryPageGallery = ({ fleetCategory }: { fleetCategory?: CmsFleetCategory }) => {
    const theme = useTheme();
    const [galleryOpen, setGalleryOpen] = React.useState(false);
    const [galleryStartIndex, setGalleryStartIndex] = React.useState<number>(0);
    const handleClose = () => setGalleryOpen(false);
    if (!fleetCategory) {
        return null;
    }
    const largeSizes = `
                            (max-width: ${theme.breakpoints.values.xl}${theme.breakpoints.unit}) 3840px,
                            (max-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) ${theme.breakpoints.values.lg}${theme.breakpoints.unit},
                            (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) ${theme.breakpoints.values.md}${theme.breakpoints.unit},
                            (max-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) ${theme.breakpoints.values.sm}${theme.breakpoints.unit},
                    `;
    const smallSize = `
                            (max-width: ${theme.breakpoints.values.xl}${theme.breakpoints.unit}) ${Math.ceil(3840 / 3)}px,
                            (max-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) ${Math.ceil(theme.breakpoints.values.lg / 3)}${theme.breakpoints.unit},
                            (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) ${Math.ceil(theme.breakpoints.values.md / 3)}${theme.breakpoints.unit},
                            (max-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) ${Math.ceil(theme.breakpoints.values.sm / 3)}${theme.breakpoints.unit},
                    `;

    const handleClick = (image: Exclude<CmsFleetCategory['gallery'], undefined>[0]) => {
        if (!fleetCategory.gallery) {
            return;
        }
        const index = fleetCategory.gallery.findIndex((item) => image === item);
        setGalleryStartIndex(index || 0);
        setGalleryOpen(true);
    };

    if (!fleetCategory.gallery?.length) {
        return;
    }

    return (
        <>
            <Box
                sx={(theme) => ({
                    mb: 6,
                    height: {
                        xs: `${theme.settings.bannerHeights.small}px`,
                        sm: `${theme.settings.bannerHeights.small}px`,
                        md: `${theme.settings.bannerHeights.medium}px`,
                        lg: `${theme.settings.bannerHeights.medium}px`,
                        xl: `${theme.settings.bannerHeights.large}px`,
                    },
                    flex: {
                        xs: `0 0 ${theme.settings.bannerHeights.small}px`,
                        sm: `0 0 ${theme.settings.bannerHeights.small}px`,
                        md: `0 0 ${theme.settings.bannerHeights.medium}px`,
                        lg: `0 0 ${theme.settings.bannerHeights.medium}px`,
                        xl: `0 0 ${theme.settings.bannerHeights.large}px`,
                    },
                    overflow: 'hidden',
                    display: 'grid',
                    gridGap: 0,
                    gridTemplateColumns: {
                        xs: '1fr 1fr 1fr 1fr',
                        sm: '1fr 1fr 1fr',
                    },
                    gridTemplateRows: '1fr 1fr',
                    gridTemplateAreas: {
                        xs: '"first first first first" "second second third third"',
                        sm: '"first first second" "first first third"',
                    },
                    position: 'relative',
                })}
            >
                <Box sx={{ position: 'relative', gridArea: 'first' }}>
                    <FleetCategoryCatalogImage
                        image={fleetCategory.gallery[0]}
                        sizes={largeSizes}
                        onClick={() => {
                            if (!fleetCategory.gallery?.length) {
                                return;
                            }
                            return handleClick(fleetCategory.gallery[0]);
                        }}
                    />
                </Box>
                {fleetCategory.gallery.slice(1, 3).map((image, i) => (
                    <Box sx={{ position: 'relative', gridArea: i === 0 ? 'second' : 'third' }} key={i}>
                        <FleetCategoryCatalogImage key={image.original} image={image} sizes={smallSize} onClick={() => handleClick(image)} />
                    </Box>
                ))}
            </Box>
            <FleetCategoryPageGalleryDialog onClose={handleClose} open={galleryOpen} maxWidth="lg" fleetCategory={fleetCategory} openIndex={galleryStartIndex} />
        </>
    );
};
