'use client';

import React from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { mergeSx } from '@jucy-ui/appearance';
import { CmsBaseBlock } from '@/services/cms/blocks/BaseCmsBlock';
import { headingFontSizes } from '@/styles/theme';


export interface BlockTitleProps extends CmsBaseBlock {
    sx?: SxProps<Theme>;
}

export const BlockTitle = ({ sx, title, showTitle, titleTag, titleStyle }: BlockTitleProps) => {
    if (!title || !showTitle) {
        return null;
    }

    const tag = titleTag?.toLowerCase() || 'default';
    const component = (tag === 'default' ? 'h2' : tag) as React.ElementType;
    return (
        <Typography variant="title1" component={component} sx={mergeSx(getTitleStyle(titleStyle), sx)}>
            {title}
        </Typography>
    );
};

const getTitleStyle = (titleStyle: string | undefined) => {
    if (titleStyle === 'Hero') {
        return {
            mb: 4,
            textTransform: 'uppercase',
            ...headingFontSizes.h1,
            textAlign: 'center',
            px: 2,
        };
    }

    if (titleStyle === 'Small') {
        return {
            fontSize: '20px',
        };
    }

    return {
        mb: 4,
    };
};
