import * as React from 'react';
import Box from '@mui/material/Box';
import dynamic from 'next/dynamic';
import { EditableDateField } from '@/components/UserDefinedForm/Fields/EditableDateField';
import { EditableFormHeading } from '@/components/UserDefinedForm/Fields/EditableFormHeading';
import { CmsEditableField } from '@/services/cms/userDefinedForm/CmsEditableField';

const EditableCheckboxField = dynamic(() => import('./Fields/EditableCheckboxField').then((mod) => mod.EditableCheckboxField));
const EditableCheckboxGroupField = dynamic(() => import('./Fields/EditableCheckboxGroupField').then((mod) => mod.EditableCheckboxGroupField));
const EditableCountryDropdownField = dynamic(() => import('./Fields/EditableCountryDropdownField').then((mod) => mod.EditableCountryDropdownField));
const EditableDropdownField = dynamic(() => import('./Fields/EditableDropdownField').then((mod) => mod.EditableDropdownField));
const EditableEmailField = dynamic(() => import('./Fields/EditableEmailField').then((mod) => mod.EditableEmailField));
const EditableFileField = dynamic(() => import('./Fields/EditableFileField').then((mod) => mod.EditableFileField));
const EditableFormStep = dynamic(() => import('./Fields/EditableFormStep').then((mod) => mod.EditableFormStep));
const EditableLiteralField = dynamic(() => import('./Fields/EditableLiteralField').then((mod) => mod.EditableLiteralField));
const EditableNumericField = dynamic(() => import('./Fields/EditableNumericField').then((mod) => mod.EditableNumericField));
const EditableRadioField = dynamic(() => import('./Fields/EditableRadioField').then((mod) => mod.EditableRadioField));
const EditableTextField = dynamic(() => import('./Fields/EditableTextField').then((mod) => mod.EditableTextField));

export interface UserDefinedFormFieldProps {
    field: CmsEditableField;
}

export const UserDefinedFormField = ({ field }: UserDefinedFormFieldProps) => {
    if (field.className === 'EditableCheckbox') {
        return <EditableCheckboxField {...field} />;
    } else if (field.className === 'EditableCheckboxGroupField') {
        return <EditableCheckboxGroupField {...field} />;
    } else if (field.className === 'EditableCountryDropdownField') {
        return <EditableCountryDropdownField {...field} />;
    } else if (field.className === 'EditableDropdown') {
        return <EditableDropdownField {...field} />;
    } else if (field.className === 'EditableEmailField') {
        return <EditableEmailField {...field} />;
    } else if (field.className === 'EditableDateField') {
        return <EditableDateField {...field} />;
    } else if (field.className === 'EditableFileField') {
        return <EditableFileField {...field} />;
    } else if (field.className === 'EditableFormStep') {
        return <EditableFormStep {...field} />;
    } else if (field.className === 'EditableLiteralField') {
        return <EditableLiteralField {...field} />;
    } else if (field.className === 'EditableNumericField') {
        return <EditableNumericField {...field} />;
    } else if (field.className === 'EditableRadioField') {
        return <EditableRadioField {...field} />;
    } else if (field.className === 'EditableTextField') {
        return <EditableTextField {...field} />;
    } else if (field.className === 'EditableFormHeading') {
        return <EditableFormHeading {...field} />;
    }

    return <Box>Unknown page type {field?.className}</Box>;
};
