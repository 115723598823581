export const serializeFormData = (data: Record<string, unknown>) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        const field = document.querySelector(`[name="${key}"]`);
        if (field?.tagName === 'INPUT' && field?.getAttribute('type') === 'file') {
            const file = (field as HTMLInputElement).files?.[0];
            if (file) {
                formData.append(key, file);
            }
            continue;
        } else if (value === undefined || value === null) {
            continue;
        }
        formData.append(key, value as string);
    }
    return formData;
};
