import { z } from 'zod';

export const formSubmissionError = z.object({
    fieldName: z.string(),
    message: z.string(),
    messageCast: z.string(),
    messageType: z.string(),
});

export type FormSubmissionError = z.infer<typeof formSubmissionError>;
