'use client';

import { APIProvider, Map, MapProps } from '@vis.gl/react-google-maps';
import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { env } from '@/env';

const mapDefaults: MapProps = {
    defaultZoom: 15,
    defaultCenter: { lat: -36.8406, lng: 174.74 },
    mapTypeId: 'roadmap',
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
};

export interface JucyMapContainerProps {
    children: React.ReactNode;
}

export const JucyMapContainer = ({ children }: JucyMapContainerProps) => {
    const [loading, setLoading] = React.useState(true);
    return (
        <>
            <Fade in={loading}>
                <Box
                    sx={{
                        position: 'absolute',
                        inset: 0,
                        margin: 'auto',
                        zIndex: 0,
                        width: '40px',
                        height: '40px',
                    }}
                >
                    <CircularProgress />
                </Box>
            </Fade>
            <Fade in={!loading}>
                <Box
                    sx={{
                        width: '100%',
                        height: '478px',
                    }}
                >
                    <APIProvider apiKey={env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY} onLoad={() => setLoading(false)}>
                        <Map mapId={env.NEXT_PUBLIC_GOOGLE_MAP_ID} {...mapDefaults}>
                            {children}
                        </Map>
                    </APIProvider>
                </Box>
            </Fade>
        </>
    );
};
