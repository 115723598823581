import Box from '@mui/material/Box';
import { UserDefinedFormField } from '@/components/UserDefinedForm/UserDefinedFormField';
import { CmsEditableField } from '@/services/cms/userDefinedForm/CmsEditableField';
import { CmsEditableFormStepFieldData } from '@/services/cms/userDefinedForm/CmsEditableFormStepFieldData';

export interface EditableFormStepProps extends CmsEditableFormStepFieldData {
    fields?: CmsEditableField[];
}

export const EditableFormStep = (props: EditableFormStepProps) => (
    <Box>
        {props.title}
        {props.fields?.map((field) => <UserDefinedFormField field={field} key={field.name} />)}
    </Box>
);
