'use client';

import { useState } from 'react';
import { FieldPath } from 'react-hook-form';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { formControlClasses, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { useLocale } from '@jucy-ui/components';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { EditableFormStep } from '@/components/UserDefinedForm/Fields/EditableFormStep';
import { extractDefaultValues } from '@/components/UserDefinedForm/lib/extractDefaultValues';
import { groupEditableFields } from '@/components/UserDefinedForm/lib/groupEditableFields';
import { postUserDefinedFormData } from '@/components/UserDefinedForm/lib/postUserDefinedFormData';
import { serializeFormData } from '@/components/UserDefinedForm/lib/searialiseFormData';
import { useSite } from '@/hooks/useSite';
import { CmsUserDefinedFormPageData } from '@/services/cms/pages/CmsUserDefinedFormPageData';
import { CmsEditableField } from '@/services/cms/userDefinedForm/CmsEditableField';

export interface UserDefinedFormProps {
    onCompleteMessage: CmsUserDefinedFormPageData['onCompleteMessage'];
    showClearButton: CmsUserDefinedFormPageData['showClearButton'];
    enableLiveValidation: CmsUserDefinedFormPageData['enableLiveValidation'];
    submitButtonText: CmsUserDefinedFormPageData['submitButtonText'];
    clearButtonText: CmsUserDefinedFormPageData['clearButtonText'];
    fields: CmsEditableField[];
    formClassName: string;
    formId: number;
}

export const UserDefinedForm = ({ onCompleteMessage, showClearButton, fields, submitButtonText, clearButtonText, formClassName, formId }: UserDefinedFormProps) => {
    const fieldSteps = groupEditableFields(fields);
    const [complete, setComplete] = useState(false);
    const site = useSite();
    const locale = useLocale();
    const cmsLocale = site.locales.find((l) => l.locale === locale);
    const defaultFormValues = {
        siteId: site.id,
        locale: cmsLocale?.locale,
        formId,
        formClassName,
        ...extractDefaultValues(fields),
    };
    const form = useForm({
        defaultValues: defaultFormValues,
    });
    return (
        <UserDefinedFormRoot>
            {complete ? (
                <CmsHtmlContent html={onCompleteMessage} />
            ) : (
                <FormContainer
                    formContext={form}
                    onSuccess={async (data) => {
                        const formData = serializeFormData(data);
                        const response = await postUserDefinedFormData(formData);
                        if (response.errors) {
                            for (const error of response.errors) {
                                if (error.fieldName) {
                                    form.setError(error.fieldName as FieldPath<typeof defaultFormValues>, { type: 'custom', message: error.message });
                                } else {
                                    form.setError('root', { type: 'custom', message: error.message });
                                }
                            }
                            return;
                        }
                        setComplete(true);
                    }}
                >
                    {fieldSteps.map((field) => (
                        <EditableFormStep {...field} key={field.name} />
                    ))}

                    {showClearButton ? <Button type="reset">{clearButtonText || 'Clear'}</Button> : null}
                    <Button type="submit">{submitButtonText || 'Submit'}</Button>
                </FormContainer>
            )}
        </UserDefinedFormRoot>
    );
};

export const UserDefinedFormRoot = styled('div')(({ theme }) => ({
    [`& .${formControlClasses.root}`]: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));
