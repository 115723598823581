import { formSubmissionResponse } from '@/services/cms/userDefinedForm/formSubmissionResponse';

export const postUserDefinedFormData = async (formData: FormData) => {
    const res = await fetch('/api/form-action', {
        method: 'POST',
        body: formData,
    });
    const json = await res.json();
    const data = formSubmissionResponse.parse(json);
    return data;
};
